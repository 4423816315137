import React from "react";
import config from "../lib/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            <div className="row align-items-center">
              <div className="col-md-6">
                {/* <p className="para">Smart Contract:</p> */}
                {/* <p className="para mb-3">
                  <a
                    target="_blank"
                    href={`${config.bscscan}address/${config.GolfiContract}`}
                  >
                    {config.GolfiContract}
                  </a>
                </p>
                <p>Support : support@golfinos.com</p> */}
              </div>
              <div className="col-md-6">
                <ul className="footer_social_links">
                  <li>
                    <a href="#" target="_blank">
                      <img
                        src={require("../assets/images/telegram.png")}
                        className="img-fluid"
                      />{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
