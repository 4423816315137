import axios from "axios";
import config from "../lib/config";

export const getUserDetails = async (data) => {
  try {
    console.log(data, "addresswaedfsac");
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getUserDetails`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const WithdrawSave = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/WithdrawSave`,
      data
    });
    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const CancelWithdrawSave = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/CancelWithdrawSave`,
      data
    });
    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const JoinPlan = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/JoinPlan`,
      data
    });
    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const ReJoin = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ReJoin`,
      data
    });
    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getTotalUP = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getTotalUP`
    });
    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getUserDetails1 = async (data) => {
  try {
    console.log(data, "addresswaedfsac");
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getUserDetails1`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const getPlanDetails = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getPlanDetails`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSignatureRejoin = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignatureRejoin`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSignature = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignature`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getWithdrawHis = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getWithdrawHis`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getRoyaltiData = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getRoyaltiData`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getUserRankInfo = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getUserRankInfo`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getGenealogyTree = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getGenealogyTree`,
      data: data
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

//Faq
export const get_faq_list = async (data) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_faq_list`,
      data: data
    });
    return {
      result: resp.data.result,
      status: resp.data.status
    };
  } catch (err) {
    console.log(err);
  }
};

export const get_user_income_hist = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get_user_income_hist`,
      data: data
    });
    return {
      result: resp.data.result,
      result1: resp.data.result1,
      status: resp.data.status
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSettingsData = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getSettingsData`
    });
    return {
      result: resp.data.result,
      result1: resp.data.result1,
      status: resp.data.status
    };
  } catch (err) {
    console.log(err);
  }
};

export const getRankHistory = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getRankHistory`,
      data: data
    });
    return {
      result: resp.data.result,
      status: resp.data.status
    };
  } catch (err) {
    console.log(err);
  }
};
